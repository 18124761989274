import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";

import EnquiryNow from "../common/EnquiryNow3";
 

const OtherCommittees = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    

      <Topnav />
      <Menubar />
      <div className="comminnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Other Committees</h1>
          <div  onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Other Committees</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">Other Committees</h2>

            <div className="row">
              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Examination Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. B. P. Bhol </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Rani Jha </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Garima Diwan </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. N. Kumar Swamy </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Takeshwar Kaushik </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Govinda Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Poonam Verma </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rajendra Prasad Gendre </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Vimla Sona </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Yugal Kishor Rajput </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mrs. Keshar Lata Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Discipline Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. Gokul Prasad Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Sidharth Mishra </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Sanjiv Jangde </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Hemant Pandey </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Lukeshwari Verma </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Lalita Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Training, Placement and Entrepreneurship Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. Rani Jha </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Poonam Verma </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Garima Diwan </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Khemanshu Kumar Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">Hostel Committee</h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Mr. Arjun Singh </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Gaurav Kumar </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Poonam Verma </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Ram Krishna Deshmukh </td>
                          <td className="small">Member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Health and Hygiene Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. Garima Diwan </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Yugal Kishor Rajput </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rajendra Kumar Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Ashwanee Kumar Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Lukeshwari Verma </td>
                          <td className="small">Member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      NCC & NSS Committees
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. Gokul Prasad Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Preetam Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Pukhraj Yadav </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Vimla Sona </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Ashwani Kumar Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Anti Ragging Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Prof. (Dr) AnandMahalwar </td>
                          <td className="small">Chairman </td>
                        </tr>
                        <tr>
                          <td className="small">Dr. B. P. Bhol </td>
                          <td className="small">Registrar</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Sandeep Kumar </td>
                          <td className="small">DSW, Member,</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. N. Kumar Swamy </td>
                          <td className="small">ARC, Member,</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rajendra Prasad Gendre </td>
                          <td className="small">ARC, Member, </td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Poonam Rajput </td>
                          <td className="small">ARC, Member </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. RamkrishnaDeshmukh </td>
                          <td className="small">Hostel Warden (Boys)</td>
                        </tr>
                        <tr>
                          <td className="small">Smt. KesharlataSahu </td>
                          <td className="small">Hostel Warden (Girls) </td>
                        </tr>
                        <tr>
                          <td className="small">TI, Chhura </td>
                          <td className="small">
                            Member representative of Police Admin
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. YashwantYadaw (Media) </td>
                          <td className="small">Member Local Media </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. SheetalDhruw (NGO) </td>
                          <td className="small">Member NGO </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. PukhrajYadaw </td>
                          <td className="small">Member Non teaching</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Arjun Singh </td>
                          <td className="small">Member (Administration)</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Purshottam </td>
                          <td className="small">Member Parents </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Mahesh Kumar Sahu </td>
                          <td className="small">Member Parents </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Jaykumar </td>
                          <td className="small">Member Student – Fresher’s</td>
                        </tr>
                        <tr>
                          <td className="small">Monika Yadu </td>
                          <td className="small">Member Student – Fresher’s</td>
                        </tr>
                        <tr>
                          <td className="small">Kishan Dewangan </td>
                          <td className="small">Member Senior Student</td>
                        </tr>
                        <tr>
                          <td className="small">Harsh Yadu </td>
                          <td className="small">Member Senior Student</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">ST/SC Committee</h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">
                            Dr. Anand Mahalwar, Vice-Chancellor.
                          </td>
                          <td className="small">Chairman </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Dr. N. Kumar Swamy, Dean Academics
                          </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Dr. Sandeep Sahu, Dean of Student Affairs
                          </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Mr. Sanjeev Jandge, Asst. Prof.
                          </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Mr. Rajendra Prasad Gendre, Asst. Prof
                          </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Vimla Sona, Asst. Prof. </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Mr. Mohit Ram Chelak, Asst. Prof.
                          </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Ms. Lukeshwari Verma, Asst. Prof.
                          </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Mr. Vagesh Kumbhakar, Asst. Prof.
                          </td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">
                            Dr. Barada Prasad Bhol, Registrar
                          </td>
                          <td className="small">Member Secretary </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">Other Backward Class Committee</h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                    <thead className="small text-uppercase bg-body text-muted">
                    <tr>
                        <th>Name of Member</th>
                        <th>Designation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="small">Dr. Sandeep Sahu, DSW</td>
                        <td className="small">Convener </td>
                    </tr>
                    <tr>
                        <td className="small">Dr. N.K Swamy, Director IQAC </td>
                        <td className="small">Member Secretary</td>
                    </tr>
                    <tr>
                        <td className="small">Dr. Preetam Sahu, Asst. Prof. Faculty of Arts & Humanities</td>
                        <td className="small">Member </td>
                    </tr>
                    <tr>
                        <td className="small">Dr. Gokul Prasad Sahu, Asst. Prof. Faculty of Science </td>
                        <td className="small">Member </td>
                    </tr>
                    <tr>
                        <td className="small">Dr. Garima Diwan, Associate Prof. Faculty of Arts & Humanities</td>
                        <td className="small">Member </td>
                    </tr>
                    <tr>
                        <td className="small">Dr. Poonam Verma, Asst. Prof. Faculty of Science</td>
                        <td className="small">Member </td>
                    </tr>
                    <tr>
                        <td className="small">Mr. Rameshwar Nishad, Asst. Prof. Faculty of Science </td>
                        <td className="small">Liaison officer for OBC Cell (nominated by Vice Chancellor) </td>
                    </tr>
                    <tr>
                        <td className="small">Dr. Ram Krishna Deshmukh, Asst. Prof. Faculty of Science</td>
                        <td className="small">Liaison officer for OBC Cell (nominated by Vice Chancellor) </td>
                    </tr>
                </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Student Counselling Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. Barada Prasad Bhol </td>
                          <td className="small">Registrar, ISBM University</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. N. Kumar Swamy</td>
                          <td className="small">Dean Academics, ISBM University</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Garima Diwan</td>
                          <td className="small">Faculty of Arts & Humanities</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Abha Tamrakar </td>
                          <td className="small">
                            Faculty of Information Technology
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Dr Samata Jain</td>
                          <td className="small">
                            Faculty of Commerce & Management
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Poonam Verma</td>
                          <td className="small">Faculty of Science</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Vimla Sona</td>
                          <td className="small">
                            Faculty of Library & Information Science
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Yugal Kishor Rajput</td>
                          <td className="small">Faculty of Pharmacy</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rajendra Kumar Gendre</td>
                          <td className="small">Faculty of Law</td>
                        </tr>
                        <tr>
                          <td className="small">Mr.Khemchand Sahu</td>
                          <td className="small">Student Representative (BA)</td>
                        </tr>
                        <tr>
                          <td className="small">Mr.Chanchal</td>
                          <td className="small">Student Representative (B.Sc)</td>
                        </tr>
                        <tr>
                          <td className="small">Mr.Ishwar Singh</td>
                          <td className="small">Student Representative (M.Sc)</td>
                        </tr>
                        <tr>
                          <td className="small">Mr.Harsh Choudhari</td>
                          <td className="small">Student Representative </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Industry Interaction Cell
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Designation</th>
                          <th>Name of Member</th>
                          <th>Affiliation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Chairperson</td>
                          <td className="small">Dr. Rani Jha </td>
                          <td className="small">Joint Registrar </td>
                        </tr>
                        <tr>
                          <td className="small">Member</td>
                          <td className="small">Mr. Arjun Singh </td>
                          <td className="small">Director Campus </td>
                        </tr>
                        <tr>
                          <td className="small">Member</td>
                          <td className="small">Mr. Takeshwar Kaushik </td>
                          <td className="small">
                            Member of faculty, School of Engineering &amp;
                            Technology
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Member</td>
                          <td className="small">Mr. Khemanshu Sahu </td>
                          <td className="small">
                            Member of faculty, School of Engineering &amp;
                            Technology
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Member</td>
                          <td className="small">Mr. Ashwanee Kumar Sahu</td>
                          <td className="small">
                            Member of faculty, School of Pharmacy
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Member </td>
                          <td className="small">Ms. Poonam Rajput </td>
                          <td className="small">
                            Member of faculty, School of Pharmacy
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Internal Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Designation</th>
                          <th>Name of Member</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. N. Kumar Swamy </td>
                          <td className="small">Principal </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Takeshwar Kaushik </td>
                          <td className="small">Member of faculty </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Khemanshu Sahu </td>
                          <td className="small">Member of faculty </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Chhannu Thakur </td>
                          <td className="small">Non-teaching staff </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Raghvendra </td>
                          <td className="small">Non-teaching staff </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Gautam </td>
                          <td className="small">Students </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rupendra </td>
                          <td className="small">Students </td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Moksha Rani Sahu</td>
                          <td className="small">Students </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Internal Complaints Committee under the Sexual Harassment
                      of Women at Workplace (Prevention, Prohibition and
                      Redressal) Act, 2013
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr. Garima Diwan </td>
                          <td className="small">Chairperson </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rajendra Prasad Gendre</td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Ashwani Kumar Sahu </td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Mrs. Rameshwari Yadaw</td>
                          <td className="small">Member</td>
                        </tr>
                        <tr>
                          <td className="small">Ms Saniya Dhruw</td>
                          <td className="small">Member </td>
                        </tr>
                        <tr>
                          <td className="small">Ms Anju</td>
                          <td className="small">Membery</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">Sport Committee</h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Mr. Ashwani Kumar Sahu</td>
                          <td className="small">
                            Asst. Prof. School of Arts &amp; Humanities
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Gokul Prasad </td>
                          <td className="small">Asst. Prof. School of Science </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Hemant Pandey </td>
                          <td className="small">Asst. Prof. School of Science </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Dusyant Yadav </td>
                          <td className="small">
                            Asst. Prof. School of Engineering &amp; Technology
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Ranu Verma </td>
                          <td className="small">Asst. Prof. School of Science </td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Rekha Sahu </td>
                          <td className="small">Asst. Prof. School of Science </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Chandramani Sahu </td>
                          <td className="small">Asst. Prof. School of IT </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Sanjiv Jangde </td>
                          <td className="small">
                            Asst. Prof. School of Engineering &amp; Technology
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Cultural Committee
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Ms. Vimla Sona </td>
                          <td className="small">
                            Asst. Prof. School of Library & Information Science
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Preetam </td>
                          <td className="small">
                            Asst. Prof. School of Arts & Humanities
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Poonam Rajput </td>
                          <td className="small">Asst. Prof. School of Pharmacy</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Pukhraj Yadav</td>
                          <td className="small">Asst. Librarian</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Lalita Sahu </td>
                          <td className="small">Asst. Prof. School of Science </td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rajendra Kumar Sahu </td>
                          <td className="small">Asst. Prof. School of Pharmacy</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Gaytri </td>
                          <td className="small">Student Member (M.Sc)</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Akash Barai</td>
                          <td className="small">Student Member (BBA LLB) </td>
                        </tr>
                        <tr>
                          <td className="small">Mr.Ved Prakash</td>
                          <td className="small">Student Member (B.Pharma)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      Gender Sensitization Cell
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Category</th>
                          <th>Name of Member</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Presiding Officer </td>
                          <td className="small">Dr. Garima Diwan </td>
                        </tr>
                        <tr>
                          <td className="small">Member </td>
                          <td className="small">
                            Ms. Khusboo Rajpoot
                            <br /> Dr. Poonam Verma
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Member</td>
                          <td className="small">
                            Ms. Rekha Sahu
                            <br /> Mrs. Nimmi Sahu
                          </td>
                        </tr>
                        <tr>
                          <td className="small">Member</td>
                          <td className="small">
                            Harsh Yadu (BA)
                            <br /> Prasant Yadaw (B.Com)
                            <br /> Sohendra Sahu (B.Sc)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-6 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className="card-header align-items-center table-title">
                    <h5 className="mb-0 text-white fw-600">
                      STUDENTS' GRIEVANCE REDRESSAL COMMITTEE
                    </h5>
                  </div>
                  <div className="table-responsive committe-table">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Name of Member</th>
                          <th>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="small">Dr.Bhupendra Kumar</td>
                          <td className="small">Chairperson, DSW</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Garima Diwan</td>
                          <td className="small">HOD SO&AH</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Rajendra Kumar Gendre</td>
                          <td className="small">Member HOD Law</td>
                        </tr>
                        <tr>
                          <td className="small">Dr. Poonam Verma </td>
                          <td className="small">Member HOD SOS</td>
                        </tr>
                        <tr>
                          <td className="small">Ms. Vimla Sona</td>
                          <td className="small">Member HOD SOL& I</td>
                        </tr>
                        <tr>
                          <td className="small">Mr. Bhagwan singh</td>
                          <td className="small">Student Member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default OtherCommittees;
