import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const Thankyou = () => {
  useEffect(() => {
    // Redirect to the ISBM University homepage after 1 second
    const timer = setTimeout(() => {
      window.location.href = "https://isbmuniversity.edu.in";
    }, 5000);

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="col-md-6 p-4">
        <div
          className="border border-3 border-success"
          style={{ borderColor: "#06355F !important" }}
        ></div>
        <div className="card bg-white shadow p-5">
          <div className="mb-4 text-center">
            <img
              className="mb-4"
              src="https://isbmuniversity.edu.in/static/media/isbm-university.9ceb30717bf3ba95be6a.webp"
              alt="ISBM University Logo"
              style={{ width: "50%" }}
            />
          </div>
          <div className="text-center">
            <h1>Thank you for your interest!</h1>
            <p>
              Thank you for submitting your inquiry to ISBM University. We
              acknowledge the receipt of your details and would like to inform
              you that a representative from ISBM University will be in touch
              with you within the next 48 hours to provide the information you
              have requested.
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Thankyou;
