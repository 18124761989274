import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useLocation } from "react-router-dom";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { MetaTags } from "react-meta-tags";
import Footer from "../common/footer";
import EnquiryNow from "../common/EnquiryNow3";
import Allinone from "../common/Allinone.js";
import Brochure from "../../Images/Brochure/ISBM_PROSPECTUS.pdf";
import { Container, Row, Col, Breadcrumb, Modal } from "react-bootstrap";

const SchoolDetails = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const { schoolName } = useParams();
  const [courses, setCourses] = useState([]);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [schools, setSchools] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const location = useLocation();
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/coursesList/${schoolName}`
        );
        const coursesData = response.data.data;
        setCourses(coursesData);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (schoolName) {
      fetchCourses();
    }
  }, [schoolName]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/schoolsApi"
        );
        const schoolsData = response.data.data;
        setSchools(schoolsData);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/schools/${schoolName}`
        );
        const schoolData = response.data.data[0];
        setSchoolDetails(schoolData);
      } catch (error) {
        setError("Error fetching school details");
        console.error("Error fetching school details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (schoolName) {
      fetchSchoolDetails();
    }
  }, [schoolName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {schoolDetails && (
        <>
          <MetaTags>
            <title>{schoolDetails.metatitle}</title>
            <meta name="description" content={schoolDetails.metadesc} />
            <link
              rel="canonical"
              href={`https://isbmuniversity.edu.in/school-details/${schoolDetails.name}`}
            />
            <meta
              name="SearchEngines"
              content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
                DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
            />
            <meta name="Robots" content="INDEX, FOLLOW" />
            <meta
              name="Author"
              content="ISBM University, isbmuniversity.edu.in/"
            />
            <meta name="Robots" content="INDEX,ALL" />
            <meta name="YahooSeeker" content="INDEX, FOLLOW" />
            <meta name="msnbot" content="INDEX, FOLLOW" />
            <meta name="googlebot" content="Default, follow" />
            <meta name="owner" content="isbmuniversity.edu.in/" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="2 days" />
            <meta property="og:title" content={schoolDetails.metatitle} />
            <meta property="og:description" content={schoolDetails.metadesc} />
            <meta
              property="og:url"
              content={`https://isbmuniversity.edu.in/school-details/${schoolDetails.name}`}
            />
            <meta
              property="og:image"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta property="og:image:alt" content="ISBM University" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="ISBM University" />
            <meta name="twitter:title" content={schoolDetails.metatitle} />
            <meta name="twitter:description" content={schoolDetails.metadesc} />
            <meta
              name="twitter:image:src"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@ISBMUniversity" />
            <meta name="twitter:creator" content="@ISBMUniversity" />
            <meta
              name="twitter:domain"
              content="https://isbmuniversity.edu.in/"
            />
            <meta property="twitter:account_id" content="758619542734053376" />
            {schoolDetails.schema}
          </MetaTags>
          <Topnav />
          <Menubar />
          <div>
            <div
              className={`innerbackimage position-relative ${schoolDetails.banner}`}
              aria-label="Best University in Chhattisgarh"
            >
              <div class="bannerShape">
                <div class="bannerDetails" id="bannerDetails">
                  <h1 class="text-gradient mb-3">{schoolDetails.title}</h1>
                  <div
                    class="mb-3 small"
                    dangerouslySetInnerHTML={{
                      __html: schoolDetails.banner_description,
                    }}
                  ></div>

                  <div class="d-flex">
                    <button
                      type="button"
                      class="btn btn-apply me-2"
                      onClick={() => setModalShow(true)}
                    >
                      Apply Now
                    </button>
                    <EnquiryNow
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                    <a
                      class="btn btn-download"
                      href={Brochure}
                      // href = "../../Images/Brochure/ISBM_PROSPECTUS.pdf"
                      download=""
                    >
                      Download Brochure
                    </a>

                    <Link to={`/school-details/${schools.name}`}>
                      {schools.title}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-wrapper mt-5">
              {/* <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/school-details/${course.school_name}`}>
                  {course.school_name}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{course.title}</Breadcrumb.Item>
            </Breadcrumb> */}

              <div class="row">
                <div class="sidebar-section col-lg-3 p-0 mb-5">
                  <h3 class="title">Courses</h3>
                  <ul className="abcd1111 shadow card border-0">
                    {schools.map((schools) => (
                      <li
                        key={schools.id}
                        className={
                          location.pathname ===
                          `/school-details/${schools.name}`
                            ? "active"
                            : ""
                        }
                      >
                        <Link to={`/school-details/${schools.name}`}>
                          {schools.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="col-lg-12">
                      <h2 class="linkabout123">{schoolDetails.title}</h2>
                      <div
                        class="overview"
                        dangerouslySetInnerHTML={{
                          __html: schoolDetails.description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="outcomes" class="outcome py-5 color-section">
              <div class="container">
                <figure>
                  <h2 class="black_title">Course Outcomes</h2>
                  <figcaption class="blockquote-footer mb-4">
                    Uncover the findings from your studies
                  </figcaption>
                </figure>
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: schoolDetails.outcome }}
                ></div>
              </div>
            </div>
            <div className="container py-5">
              <figure>
                <h2 class="black_title">Discover Our Courses</h2>
                <figcaption class="blockquote-footer mb-4">
                  In-demand courses across industries{" "}
                </figcaption>
              </figure>
              <div className="row row-cols-1 row-cols-md-3 g-4">
                {courses.map((course) => (
                  <div key={course.id} className="col">
                    <div className="card courseBox p-0 h-100 shadow-lg mb-5 bg-body border-0">
                      <div className="card-header">
                        <Link
                          to={`/course-details/${course.name}`}
                          className="card-title font-weight-bold p-3" // Assuming course.id is used for routing
                        >
                          <h5 className="card-title font-weight-bold p-3">
                            {course.title}
                          </h5>
                        </Link>
                      </div>
                      <div className="card-body">
                        <div className="col">
                          <Row className="m-0 mb-3 d-flex">
                            <i class="fa-solid fa-user-graduate me-3"></i>
                            <p>{course.level}</p>
                          </Row>
                          <Row className="m-0 mb-3 d-flex">
                            <i class="fa-solid fa-hourglass-half  me-3"></i>
                            <p>{course.duration}</p>
                          </Row>
                          <Row className="m-0 mb-3 d-flex d-flex">
                            <i class="fa-solid fa-building-un  me-3"></i>
                            {course.gariyaband_campus ===
                              '<img src="https://isbmuniversity.edu.in/admin-portal/admin-assets/images/accept.png" alt="Trulli" width="30" height="30">' && (
                              <p>Gariyaband Campus</p>
                            )}
                            {course.raipur_campus ===
                              '<img src="https://isbmuniversity.edu.in/admin-portal/admin-assets/images/accept.png" alt="Trulli" width="30" height="30">' && (
                              <p>, Raipur Campus</p>
                            )}
                          </Row>
                          <div className="m-0 d-flex flex-wrap mt-3">
                            <div>
                              <button
                                className="btn btn-sm btn-apply btn-sm mb-2 me-2"
                                onClick={() => scrollToSection("bannerDetails")}
                              >
                                <span className="gdlr-core-content">
                                  Apply Now
                                </span>
                              </button>
                            </div>
                            <Link
                              className="btn btn-sm btn-apply btn-sm mb-2 me-2"
                              style={{
                                width: "fit-content",
                                border: "1px solid #a82682",
                              }}
                              to={`/course-details/${course.name}`} // Assuming course.id is used for routing
                            >
                              <span className="gdlr-core-content">
                                View Details
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <Allinone />
            <Footer />
            <Modal
              show={show}
              onHide={handleClose}
              centered
              className="video_schools_abcdsa"
            >
              <Modal.Body className="p-0 bg-transparent">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/jgS_lbVI428"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default SchoolDetails;
